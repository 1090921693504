import { IonButton, IonIcon } from "@ionic/react";
import { checkmarkCircle, checkmarkOutline } from "ionicons/icons";
import { useState } from "react";
import productsController from "../../../../barrel/controllers/productsController";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import HxfSelectorField from "../../HxfSelectorField/HxfSelectorField";
import HxfIonPopup from "../../ProductionUI/HxfIonPopup/HxfIonPopup";
import DefineProductParameterizedVariableValuesPopup from "../defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import styles from "./DefineProductPopup.module.scss";

interface IProps{
    onClose?:any;
    onConfirm?:any;
}
function DefineProductPopup(props:IProps){

    const {t} = useHxfTranslation();
    const [resultingProductSelected, setResultingProductSelected] = useState<any>(null);
    const [productSelected, setProductSelected] = useState<any>(null);
    const [popupDefinedPVSData, setPopupDefinedPVSData] = useState<any>(null);
    const getSelectedProductValue = () => {
        if(productSelected?.Id){
            return {label:"("+productSelected?.code+")" + productSelected?.name, value: productSelected?.Id};
        }
        return null;
    }

    const checkIfConfirmEnabled = () => {

        return resultingProductSelected !== null;
    }
    return (
        <>
                {popupDefinedPVSData && (
                <DefineProductParameterizedVariableValuesPopup
                        
                        isLoading={false}
                        currentData={popupDefinedPVSData}
                        allowCurrentDataValuesLoad={true}
                        includeFullParameterizedVariablesData={true}
                        title={t('product.parameters')}
                        onClosePopup={() => {
                            setPopupDefinedPVSData(null);
                            setProductSelected(null);
                            setResultingProductSelected(null);
                            
                        }}
                        onSuccessConfirm={(res:any) => {
                            setResultingProductSelected({...resultingProductSelected,  arrayProductParameterizedVariables:res});

                            setPopupDefinedPVSData(null);
                 
                        }}
                />
        )}
        {!popupDefinedPVSData && (
            <>
                <HxfIonPopup onClose={() => {
                    props.onClose();
                }} title={t('select.a.product')} modelSettings={{contentHeightPct:85,customPopupMaxHeight:'650px'}} bottomConfirmationContainer={(
                    <div className={styles.container}>

                    <div>
                    <IonButton
                        disabled={!checkIfConfirmEnabled()}
                        className={styles.btnConfirm}
                        data-btn={"select-a-product-confirm-btn"}
                        size="large"
                        onClick={() => {
                            props.onConfirm(resultingProductSelected);
                        }}
                        >
                        <IonIcon slot="start" size="large" icon={checkmarkOutline} />
                        {t("confirm")}
                        </IonButton>
                        
                    </div>
                    </div>
                )} content={(
                    <div className={styles.container}>
                            <HxfSelectorField
                                    style={{width:'100%'}}
                                    dataInputFieldAttributeValue="adjust-product-selector"
                                    searchingFilterKey={"searchBarFilter"}
                                        disabled={false}
                                        error={
                                        false
                                        }
                                        onChange={(evt, val) => {

                
               
                                            if(!val?.fetchedData){
                                                setProductSelected(null);
                                                setPopupDefinedPVSData(null);
                                                setResultingProductSelected(null);
                                            }else{
                                                setProductSelected(val?.fetchedData);
                                                setResultingProductSelected({
                                                    Id_Product:val?.fetchedData?.Id,
                                                    name:val?.fetchedData?.name,
                                                    code:val?.fetchedData?.code
                                                })
                                            }
                                            
                                            if(val?.fetchedData?.arrayProductParameterizedVariables?.length > 0){
                                                //ask to define ppvvs
                                                setPopupDefinedPVSData({
                                                    //_indexSelected:index,
                                                    Id_Product:val.fetchedData.Id,
                                                    name:val.fetchedData.name,
                                                    code:val.fetchedData.code,
                                                    arrayProductParameterizedVariables: val.fetchedData.arrayProductParameterizedVariables,
                                                    //_isAddingNew:true
                                                });
                                        
                                            }else{
                                                setPopupDefinedPVSData(null);
                                            }
                                        }}
                                        value={getSelectedProductValue()}
                                        listJsonFunction={
                                        productsController().getProductsListJson
                                        }
                                        listJsonOptions={{includeParameterizedVariables:true}}
                                        uniqueId={"defproduct_product_sel"}
                                        labelPlaceholder={t("product")}
                                    />

                                    

                                    {resultingProductSelected?.arrayProductParameterizedVariables && (
                                        <div className={styles.arrPPVVs}>
                                            {resultingProductSelected?.arrayProductParameterizedVariables.map((objPV:any, index:any) => (
                                                <div key={"arrppvv_" + index}>
                                                    <b>{objPV.name}:</b> {objPV.Value}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                
                    </div>
                )}/>
            </>
        )}

        </>
    )
}

export default DefineProductPopup;