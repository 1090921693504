import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./DefineInputsDataPopup.module.scss";
import { TextField,Button, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import HxfIonPopup from "../../ProductionUI/HxfIonPopup/HxfIonPopup";
import HxfTextfieldNumber from "../../HxfTextfieldNumber/HxfTextfieldNumber";

interface IDefInput {
    customInputIdentifier?:any;
    label:string;
    inputType:"TEXTFIELD" | "TEXTFIELD_NUMERIC";
    hasError?:boolean;
    defaultValue?:string;
    typeInputTextMaxLength?:number;
    typeInputTextHelper?:string;
    typeInputTextAutoFocus?:boolean;
    typeTextInputMultiline?:boolean;
    inputAdornment?:any;
    aboveContent?:any; //content that shows above the input
    dataInputFieldAttributeValue?:any;
}

interface IDefBtn{
    label:string;
    btnStyles?:any;
    labelStyles?:any;
    onClick?:(data: any) => any;
}

interface IWindowSettings{
    contentHeightPct?:any;
    customPopupMaxHeight?:any;
}

interface IPopup {
  onClose: any;

  
  title: string;
  topBarTitle?:string;
  arrayInputs:IDefInput[];
  arrayConfirmationButtons:IDefBtn[];
  mapValuesErrors?:any;

  windowSettings?:IWindowSettings;
}

function DefineInputsDataPopup(props: IPopup) {

    const [mapInputsValues, setMapInputsValues] = useState([]);
    const [didMount, setDidMount] = useState(false);

    const buildConfirmData = () => {
        return {mapInputsValues:mapInputsValues};
    }

    const getProperConfirmBtn = (iBtn:IDefBtn, index:number) => {

        let btnStyles:any = {};
        if(iBtn?.btnStyles){
            btnStyles = {...btnStyles, ...iBtn.btnStyles};
        }

        let labelStyles:any = {};
        if(iBtn?.labelStyles){
            labelStyles = {...labelStyles, ...iBtn.labelStyles};
        }
        return (
            <>
              <IonButton
                style={{...btnStyles}}
                className={styles.confirmButton}
                data-btn={"set-location"}
                onClick={() => {
                  if(iBtn?.onClick) {
                    iBtn.onClick(buildConfirmData());
                  }
                }}>
                <div style={{...labelStyles}}>{iBtn.label.toUpperCase()}</div>
              </IonButton>
            </>
        )
    }

    const getAboveContent = (iInput:IDefInput) => {

        if(iInput?.aboveContent){
            return (
                <>{iInput?.aboveContent}</>
            )
        }
     

        return (<></>)
    }

    const getProperInput = (iInput:IDefInput, index:number) => {

        let inputCustomIdentifier = iInput?.customInputIdentifier;
        let hasError = props?.mapValuesErrors?.[inputCustomIdentifier];

        let inputPropsData:any = {};
        if(iInput?.typeInputTextMaxLength){
            inputPropsData.maxLength = iInput.typeInputTextMaxLength;
        }

        if(iInput.inputType == "TEXTFIELD_NUMERIC"){
            return (
                <HxfTextfieldNumber
              
                dataInputFieldAttributeValue={iInput?.dataInputFieldAttributeValue}
                startAdornment={iInput?.inputAdornment ? iInput.inputAdornment : null}
                fullWidth={true} 
                centerInputText={true} error={hasError} label={iInput?.label}
                onChange={(evt:any) => {
         
                    let newDesc = evt;
                    let mapInpts:any = mapInputsValues;
                    mapInpts[index] = newDesc;
                    setMapInputsValues({...mapInpts});
            
                }} value={mapInputsValues?.[index] ?  mapInputsValues?.[index] : ""}/> 
            )
        }
        return (
            <TextField
            multiline={iInput.typeTextInputMultiline}
            autoFocus={iInput.typeInputTextAutoFocus}
            helperText
            inputProps={{...inputPropsData}}
            fullWidth
            error={hasError}
            onChange={(evt:any) => {
         
                let newDesc = evt.target.value;
                let mapInpts:any = mapInputsValues;
                mapInpts[index] = newDesc;
                setMapInputsValues({...mapInpts});
        
            }}
            value={
                mapInputsValues?.[index] ?  mapInputsValues?.[index] : ""
            }
            label={iInput?.label}
            variant="outlined"
            
            
        />
        )
    }

    useEffect(() => {
        if(!didMount){
            let mappedInputsVals:any = {};
            let arrInputs:IDefInput[] = props.arrayInputs;
            for(let i = 0; i<arrInputs.length; i++){
                mappedInputsVals[i] = arrInputs[i]?.defaultValue ? arrInputs[i]?.defaultValue : null;
            }
            setMapInputsValues(mappedInputsVals);
            setDidMount(true);
        }
    },[didMount]);

  
    return(
        <HxfIonPopup modelSettings={{
            mode:null,contentHeightPct:props?.windowSettings?.contentHeightPct ? props?.windowSettings?.contentHeightPct : 85,customPopupMaxHeight:props?.windowSettings?.customPopupMaxHeight ? props?.windowSettings?.customPopupMaxHeight  : null
        }} onClose={() => {
            props.onClose();
        }} title={props.title} content={(
           
                <div className={` ${styles.content}`}>
                    <div className={styles.inputsContainer}>
                        {props?.arrayInputs.map((objInput:IDefInput, index:number) => (
                            <div key={"inputProper_" + index}>
                                {getAboveContent(objInput)}
                                {getProperInput(objInput,index)}
                            </div>
                        ))}
     
                    </div>

                </div>
           
        )}
        
        bottomConfirmationContainer={
            (
                <div className={styles.confirmBtnsContainer}>
                    {props.arrayConfirmationButtons.map((objConfirmBtn:IDefBtn, index:number) => (
                        <div key={"confBtn_" + index}>
                            {getProperConfirmBtn(objConfirmBtn, index)}
                        </div>
                    ))}
                </div>

            )
        }
        />
    );

}

export default DefineInputsDataPopup;
