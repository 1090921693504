import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import SelectLotPopup from "../../../../../../statePopups/selectLotPopup/SelectLotPopup";
interface ITopBar{
    label:any;
}

interface ISelLocation{
    Id_WarehouseLocation?:any;
    warehouseLocationData?:any;
    Id_Warehouse?:any;
    warehouseData?:any;
    Id_Lot?:any;
    lotData?:any;
    availableQuantity?:any;
}

interface ISelectableStagedLot{
    Id_Product?:any;
    arrayProductParameterizedVariables?:any;
    locationsQuantity?:ISelLocation[];
}
interface IProps{
    onClose?:any;
    onConfirm?:any;
    topBar?:ITopBar;
    selectableLots?:ISelectableStagedLot[];
}
const ExistingLotsPopup = (props:IProps) => {
    const {t} = useHxfTranslation();
    
    return (
        <SelectLotPopup title={t('existing.lots')} onClose={props.onClose} onConfirm={props.onConfirm} topBar={props?.topBar} selectableLots={props?.selectableLots}/>
    )
}

export default ExistingLotsPopup;