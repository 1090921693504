import { useRef, useState } from "react";
import productionController from "../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import OperationActionService from "../../../../barrel/services/operationActionService";
import ProductionService from "../../../../barrel/services/productionService";
import { useIonToast } from "@ionic/react";
import { useHistory } from "react-router";
import { dispatchProductionOrderSelectionStore } from "../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { dispatchActionScreenManagerStore } from "./ActionScreensManager/ActionScreenManagerStore";
import { useCancelToken } from "../../../../barrel/hooks/useCancelToken";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import ActionManagerService from "../../../../barrel/services/actionManagerService";
import { productionOperationSessionStats } from "./ProductionOperationStore";

/*
  currentProductionOrderSelectionData,
  successEndOperationCallback,
  failedEndOperationCallback,
  callbackEnableConfirmButtons
*/

interface IProps{
  currentProductionOrderSelectionData:any;
  successEndOperationCallback:any;
  failedEndOperationCallback:any;
}
function useHookProductionOperationHandler(
properties:IProps
) {
  const localStorage = useLocalStorageHandler();
  const feedbackService = useFeedbackService();
  const productionOrderSelectionData = properties.currentProductionOrderSelectionData;
  const postingProductionDataObject = useRef({});
  const productionService = ProductionService();
  const operationActionService = OperationActionService();
  const { appState } = useGlobalState();
  const operationSessionStats = productionOperationSessionStats();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "inHookProductionOperationHandler",
  });

  const [isProcessingActionOperationEnd, setIsProcessingActionOperationEnd] = useState(false);

  function resetPostingProductionDataObject() {
    postingProductionDataObject.current = {};
  }


  function returnActionResult(object:any) {
    postingProductionDataObject.current = {
      ...postingProductionDataObject.current,
      ...object,
    };
  }

  function getCurrentPostingProductionDataObject() {
    return postingProductionDataObject.current;
  }

  const isActionBackEndType = (actType:any) => {
    return actType === "BACKEND_ACTION_TYPE";
  };

  const isTheLastAction = () => {
    let targetIndex = productionOrderSelectionData.currentOperationActionIndex + 1;
    return targetIndex === productionOrderSelectionData.currentOperation.actions.length-1;
  }

  function nextAction() {
    console.log("Calling next action");
    let targetIndex =
      productionOrderSelectionData.currentOperationActionIndex + 1;
    if (
      targetIndex >=
      productionOrderSelectionData.currentOperation.actions.length
    ) {
      throw "the target action index doesnt exist";
    }

    let actType = handleAction(targetIndex);
    dispatchProductionOrderSelectionStore({ type: "NEXT_OPERATION_ACTION" });

    if (isActionBackEndType(actType)) {
      dispatchProductionOrderSelectionStore({
        type: "SKIP_BACKEND_TYPE_OPERATION",
      });
    }
  }

  function handleAction(
    indexAction = productionOrderSelectionData.currentOperationActionIndex
  ) {
    //console.log("HANDLING ACT",productionOrderSelectionData);

    let operationActions =
      productionOrderSelectionData.currentOperation.actions;
    let actionData = operationActions[indexAction];
    console.log("HANDLING ACT Code: ", actionData["code"]);
    let foundAction = false;

    if(actionData["code"] === "ASK_MACHINE_STOP_USAGE"){
      foundAction = true;
      return actionAskMachineStopUsage(actionData);
    }

    if(actionData["code"] === "IDENTIFY_PRODUCT_SERIAL"){
      foundAction = true;
      return actionIdentifyProductSerial(actionData);
    }

    if(actionData["code"] === "MATCH_FIXED_VARIABLES"){
      foundAction = true;
      return actionMatchFixedVariables(actionData);
    }

    if (actionData["code"] === "PRODUCE_PRODUCT_QUANTITY") {
      foundAction = true;
      return actionProduceProductQuantity(actionData);
    }

    if(actionData["code"] === "PRINT_SERIALIZATION_LABELS"){
      foundAction = true;
      return actionPrintSerializationLabels(actionData);
    }

    if(actionData["code"] === "REGISTER_PRODUCT_SERIALIZATION"){
      foundAction = true;
      return actionRegisterProductSerialization(actionData);
    }

    if (actionData["code"] === "SCALE_PRODUCTIVITY_QUANTITY") {
      foundAction = true;
      return actionScaleProductivityQuantity(actionData);
    }

    if(actionData["code"] === "PRODUCE_TRANSFORMATION_FROM_MACHINE_ADJUSTMENT"){
      foundAction = true;
      return actionScreenProduceTransformationFromMachineAdjustment(actionData);
    }

    if (actionData["code"] === "ASK_USE_MACHINE_NEXT") {
      foundAction = true;
      return actionAskUseMachine(actionData);
    }

    if (actionData["code"] === "ASK_USE_MACHINE_CURRENT") {
      foundAction = true;
      return actionAskUseCurrentMachine(actionData);
    }

    if (actionData["code"] === "ASK_PRINT_LABELS") {
      foundAction = true;
      return actionAskPrintLabels(actionData);
    }

    if (actionData["code"] === "ASK_PRODUCED_PRODUCT_IRREGULARITY") {
      foundAction = true;
      return actionAskProducedProductIrregularity(actionData);
    }

    if (
      actionData["code"] ===
      "DEFINE_PRODUCED_PRODUCT_PARAMETERIZED_VARIABLE_VALUES"
    ) {
      foundAction = true;
      return actionDefineProducedProductParameterizedVariableValues(actionData);
    }

    if (actionData["code"] === "ASK_BOM_CONSUMPTIONS") {
      foundAction = true;
      return actionAskBomConsumptions(actionData);
    }

    if (actionData["code"] === "FILL_CHECKLISTS") {
      foundAction = true;
      return actionFillChecklists(actionData);
    }

    if (actionData["code"] === "LATEST_MACHINE_STOP_USAGE") {
      foundAction = true;
      return actionLatestMachineStopUsage(actionData);
    }

    if (actionData["code"] === "END_OPERATION") {
      foundAction = true;
      return actionEndOperation();
    }

    if (!foundAction) {
      throw "unexpected error, action code not found";
    }
  }

  function actionEndOperation() {

    let currentOperationId = productionOrderSelectionData.currentOperation.Id;
    let productionId = productionOrderSelectionData.productionRecord.Id;
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();

    let shopfloorSetting_Redirection = productionOrderSelectionData?.currentOperation?.shopfloorSetting_redirection;

    let shopfloorSettingRedirectOnNext =
      productionOrderSelectionData?.currentOperation
        ?.shopfloorSetting_operationConfirmation ===
      "DONE_END_CURRENT";
      //todo, close all windows and set it loading
      operationActionService.closeOperationActionWindows();
  
     
      //---
    if (
      productionOrderSelectionData.isCurrentOperationTheLast ||
      shopfloorSettingRedirectOnNext
    ) {
      let postingData = postingProductionDataObject.current;
      setIsProcessingActionOperationEnd(true);
      productionController()
        .closeProduction(
          currentWorkerCode,
          productionId,
          currentOperationId,
          postingData,
          cancelToken
        )
        .then((res) => {
          setIsProcessingActionOperationEnd(false);
          console.log("success finished ", res);
          if (res?.data?.code !== 200) {
            throw "Failed production close errx789";
          }
          productionService.resetCurrentOperationProductionState();
          ActionManagerService().resetAllScreensState();
          let redir = "";
          
          if(localStorage.getSMWMode() === "SMW_MODE_MULTI"){
            redir ="/";
          }else if(!shopfloorSetting_Redirection || shopfloorSetting_Redirection === "REDIR_OPERATION_SELECTION"){
            
            redir = "/feature/production-order/" +
              productionOrderSelectionData.productionOrder.Id +
              "/po-product/" +
              productionOrderSelectionData.productionRecord
                .Id_ProductionOrder_Product;
          }else if(shopfloorSetting_Redirection === "REDIR_PRODUCTION_ORDER_SELECTION"){
            redir = "/feature/production-orders";
          }


          if (shopfloorSettingRedirectOnNext) {
            properties.successEndOperationCallback({ redirect: redir });
          } else {
           properties. successEndOperationCallback(res);
          }
        })
        .catch((res:any) => {
          setIsProcessingActionOperationEnd(false);
          if (isCancel(res)) {
            return;
          }
         

         
          console.log("Failed, showing toast, returning to the screen ", res);
          properties.failedEndOperationCallback(res);
          operationActionService.closeOperationActionWindows();
        
        });
    } else {
      //proceed to the next operation
      console.log("Proceeding to the next operation");
      operationActionService.closeOperationActionWindows();
      setIsProcessingActionOperationEnd(true);
      productionController()
        .createProduction(
          {},
          currentWorkerCode,
          productionId,
          currentOperationId,
          null,
          null,
          postingProductionDataObject.current,
          null,
          cancelToken
        )
        .then((res) => {
          setIsProcessingActionOperationEnd(false);
          productionService.resetCurrentOperationProductionState();
  
          ActionManagerService().resetAllScreensState();
          resetPostingProductionDataObject();
          //resetPostingPreActionsDataObject();
        
          operationSessionStats.increaseOperationCount(currentOperationId);
          properties.successEndOperationCallback(res);
        })
        .catch((res) => {
          setIsProcessingActionOperationEnd(false);
          if (isCancel(res)) {
            return;
          }
      
          properties.failedEndOperationCallback(res);
          operationActionService.closeOperationActionWindows();
        
        });
    }
  }


  function actionMatchFixedVariables(actionData:any){
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_MATCH_FIXED_VARIABLES",
    });
  }

  function actionPrintSerializationLabels(actionData:any){
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_PRINT_SERIALIZATION_LABELS",
    });
  }

  function actionIdentifyProductSerial(actionData:any){
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_IDENTIFY_PRODUCT_SERIAL_SCREEN",
    });
  }

  function actionAskMachineStopUsage(actionData:any){
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_ASK_MACHINE_STOP_SCREEN",
    });
  }

  function actionProduceProductQuantity(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_PRODUCT_PRODUCED_QUANTITY_SCREEN",
    });
  }

  function actionAskProducedProductIrregularity(actionData:any) {

    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_ASK_PRODUCED_PRODUCT_IRREGULARITY_SCREEN",
    });
  }

  function actionScaleProductivityQuantity(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_SCALE_PRODUCTIVITY_QUANTITY_SCREEN",
    });
  }

  function actionScreenProduceTransformationFromMachineAdjustment(actionData:any){
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_PRODUCE_TRANSFORMATION_FROM_MACHINE_ADJUSTMENT",
    });
  }


  function actionRegisterProductSerialization(actionData:any){
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({ type: "SHOW_REGISTER_PRODUCT_SERIALIZATION_SCREEN" });
  }


  function actionAskUseCurrentMachine(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({ type: "SHOW_ASK_USE_MACHINE_CURRENT_SCREEN" });
  }

  function actionAskUseMachine(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({ type: "SHOW_ASK_USE_MACHINE_NEXT_SCREEN" });
  }

  function actionAskPrintLabels(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({ type: "SHOW_ASK_PRINT_LABELS_SCREEN" });
  }

  function actionLatestMachineStopUsage(actionData:any) {
    return "BACKEND_ACTION_TYPE"; //action does nothing in the frontend its only read and considered in the backend
  }

  function actionAskBomConsumptions(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({
      type: "SHOW_ASK_BOM_CONSUMPTIONS_SCREEN",
    });
  }

  function actionFillChecklists(actionData:any) {
    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
    dispatchActionScreenManagerStore({ type: "SHOW_FILL_CHECKLISTS_SCREEN" });
  }

  function actionDefineProducedProductParameterizedVariableValues(actionData:any) {
    dispatchActionScreenManagerStore({
      type: "SHOW_DEFINE_PRODUCT_PRODUCED_PARAMETERIZED_VARIABLE_VALUES",
    });

    let actionConfig = actionData["action_config"];
    if (actionConfig === "") {
      actionConfig = {};
    } else {
      actionConfig = JSON.parse(actionData["action_config"]);
    }
    dispatchActionScreenManagerStore({
      type: "SET_OPERATION_ACTION_DATA",
      payload: actionData,
    });
    dispatchActionScreenManagerStore({
      type: "SET_CURRENT_ACTION_CONFIG",
      payload: actionConfig,
    });
  }

  return {
    isActionBackEndType,
    returnActionResult,
    handleAction,
    nextAction,
    getCurrentPostingProductionDataObject,
    isProcessingActionOperationEnd,
    isTheLastAction
  };
}
export default useHookProductionOperationHandler;
