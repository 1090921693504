
import styles from "./SelectLotPopup.module.scss";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import HxfIonPopup from "../../ProductionUI/HxfIonPopup/HxfIonPopup";
import DatesConversionService from "../../../../barrel/services/datesConversionService";
interface ITopBar{
    label:any;
}

interface ISelLocation{
    Id_WarehouseLocation?:any;
    warehouseLocationData?:any;
    Id_Warehouse?:any;
    warehouseData?:any;
    Id_Lot?:any;
    lotData?:any;
    availableQuantity?:any;
}

interface ISelectableStagedLot{
    Id_Product?:any;
    arrayProductParameterizedVariables?:any;
    locationsQuantity?:ISelLocation[];
}
interface IProps{
    onClose?:any;
    onConfirm?:any;
    topBar?:ITopBar;
    selectableLots?:ISelectableStagedLot[];
    title:any;
}
const SelectLotPopup = (props:IProps) => {
    const {t} = useHxfTranslation();


    const checkIfExpirationAllGood = (obj:any) => {
        return !obj?.lotData?.expirationDate || DatesConversionService().convertUTCStringToLocalDate(obj?.lotData?.expirationDate) > (new Date());
    }

    const checkIfStockAllGood = (obj:any) => {
        return obj?.availableQuantity > 0
    }

    const checkIfHasIssues = (obj:any) => {
        return !checkIfExpirationAllGood(obj) || !checkIfStockAllGood(obj);
    }
    return (
        <HxfIonPopup
            onClose={props.onClose}
            title={props.title}
            content={(
            <>
            {props?.topBar && (<>
                <div className={styles.topBar}>{props.topBar?.label}</div>
                <div style={{height:25}}></div>
                </>
            )}
            <div style={{margin:10}}>

                
                <div className={styles.topMessage}>{t('select.the.lot.wish.to.use')}</div>
                {props?.selectableLots && props?.selectableLots.length > 0 ? (
                    <div className={styles.stagedLotsSelection}>
                        {props?.selectableLots?.map((obj:any, index:any) => (
                            <div key={"selLot_" + index} style={{position:'relative'}}>

                                <div className={styles.stagedLot} onClick={() => {
                                    props.onConfirm({
                                        selectedLot:obj
                                    });
                                }}>
                                    <div className={styles.stagedLotNaming}>
                                        <div className={styles.stagedLotNameLabel}>{obj?.lotData?.name}</div>
                                    </div>
                                    <div className={styles.labelInfo}>{t('warehouse')}</div>
                                    <div className={`${styles.fieldValue} ${styles.fieldValueOK} }`}>
                                        <div style={{position:'relative',top:2}}><MyLocationIcon/></div>
                                        <div className={styles.relevantValues}>{obj?.warehouseData?.name}</div>
                                    </div>
                                    <div className={styles.labelInfo}>{t('available')}</div>
                                    <div className={`${styles.fieldValue} ${checkIfStockAllGood(obj) ? styles.fieldValueOK : styles.fieldValueBad}`}>
                                        <div><WarehouseIcon/></div>
                                        <div className={styles.relevantValues}>{obj?.availableQuantity}</div>
                                    </div>
                                    
                                    {obj?.lotData?.expirationDate && (
                                        <>
                                            <div className={styles.labelInfo}>{t('expiration.date')}</div>
                                            <div className={`${styles.fieldValue} ${checkIfExpirationAllGood(obj) ? styles.fieldValueOK : styles.fieldValueBad}`}>
                                                <div><HistoryToggleOffIcon/></div>
                                                <div className={styles.relevantValues}>{DatesConversionService().formattedDateSimpleLabelDateOnly(DatesConversionService().convertUTCStringToLocalDate(obj?.lotData?.expirationDate))}</div>
                                            </div>
                                        </>
                                    )}

                                    <div className={styles.selectMeIcon}>
                                         {!checkIfHasIssues(obj) ? (
                                            <div className={styles.selectMeIconStyling}><PanToolAltIcon/></div>
                                         ) : (
                                            <div className={styles.doNotSelectMeIconStyling}><PriorityHighIcon/></div>
                                         )}
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        {t('no.lot.has.been.staged')}
                    </div>
                )}

            </div>
            </>
            )}
        />
    )
}

export default SelectLotPopup;