import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
function lotsController() {
  const getLotsListJson = (extraOptions: any = null) => {
    let orderByQuery = "";
    let url = HxfAppServersService().getBackend() + "lots/listJson/?";
    if (extraOptions !== null) {
      let optionKeys = Object.keys(extraOptions);
      let addedFirst = false;
      for (let i = 0; i < optionKeys.length; i++) {
        let key = optionKeys[i];
        let value = extraOptions[key];
        if (value !== "") {
          if (!addedFirst) {
            addedFirst = true;
          } else {
            url = url + "&";
          }
          url = url + key + "=" + value;
        }
      }
    }

    return BarrelWrapper().appPostRequest(url, {});
  };

  const save =  (data = {}) => {
    let requestUrl = "lot/save";


    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {...data}
    );
    //
  }
  const getLot = (
    options = {},
    Id_Lot:any,
    cancelToken: any
  ) => {


    let requestUrl = "lot/get/" + Id_Lot;


    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {...options},
      cancelToken
    );
}

  //
  return { getLotsListJson,getLot,save };
}

export default lotsController;
