import { useEffect, useState } from "react";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfOverlayLoading from "../../../../HxfOverlayLoading/HxfOverlayLoading";
import PrintLabelPopup from "../../../../statePopups/printLabelPopup/printLabelPopup";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import useActionScreenTopBar from "../Components/hooks/useActionScreenTopBar";


interface IProps {
    callbackOnClose?:any;
    callbackEnableConfirm?:any;
    callbackSuccessConfirm?:any;
}
function ActionScreenAskPrintLabelsV2(props: IProps) {
    const {t} = useHxfTranslation();
    const actTopBar = useActionScreenTopBar();
    const currentActionScreenManager = useActionScreenManagerStore();
    const productionOrderSelectionStore= useProductionOrderSelectionStore();
    const feedbackService = useFeedbackService();
    
    const [isLoading, setIsLoading] = useState(false);
    const [didMount, setDidMount] = useState(false);

    const [showPrintLabelPopup,setShowPrintLabelPopup] = useState<any>(null);
    // states to allow clearing when loading a default stock location config

    const closeCallback = () => {
        props.callbackOnClose(
        );
    };

    useEffect(() => {
        if (!didMount) {
            //mounting

            let currentOperationActions = productionOrderSelectionStore?.currentOperation?.actions;

            let labelsList = [...currentActionScreenManager.currentActionConfig?.labelsList];

            try{
                for(let i = 0; i<labelsList.length; i++){
                    let prLotTagFillFromPPQ = labelsList[i]?.prLotTagFillFromPPQ;
                    let prProductTagBeeingProduced = labelsList[i].prProductTagBeeingProduced;

                    let additionalParameters:any = {};
                    if(prLotTagFillFromPPQ=== 1){
                        let idLotSelected = null;
    
                        
                        if(currentOperationActions){
                            for(let j = 0; j<currentOperationActions.length; j++){
                                if(currentOperationActions[j].code === "PRODUCE_PRODUCT_QUANTITY"){
                                    let tempStore = currentOperationActions[j]?._tempStore;
                                    if(tempStore?.Id_Lot){
                                        //getting lot defined in the produce product quantity
                                        idLotSelected = tempStore.Id_Lot;
                                    }
                                }
                            }
                        }
                        
                        if(!idLotSelected){
                            
                            throw "unexpected.ppq.no.lot.selected";
                        }
                       
                        additionalParameters.Id_Lot = idLotSelected;
                    }

                    if(prProductTagBeeingProduced === 1){
                        let idProduct = productionOrderSelectionStore?.product?.Id;
                        if(!idProduct){
                            throw "unexpected.sel.product.id";
                        }
                        additionalParameters.Id_Product = idProduct;
                    }
    
                    labelsList[i]._additionalParameters = additionalParameters;
                }
                let printPopupData = {printableLabels:labelsList,genericLabelData:{test:123}};
                setShowPrintLabelPopup(printPopupData);
            } catch(error) {
                console.error(error);
                feedbackService.notifyToast(t('error.unexpected'),"error");
            }

         
            
            setDidMount(true);
        }
    }, [
        didMount,
        currentActionScreenManager,
        feedbackService,
        t
    ]);

    if (isLoading) {
        return <>
            <HxfOverlayLoading/></>;
    }

    return (
        <>
            <PrintLabelPopup
                title={t('label.printing')}
                showSkipButton={true}
                onClosePopup={() => {
                
                    actTopBar.closeActionWindows();//custom close
                    props.callbackOnClose();
                }}
                onSkip={() => {
                    setShowPrintLabelPopup(null);
                    props.callbackSuccessConfirm();
                }}
                printableLabels={showPrintLabelPopup?.printableLabels ? showPrintLabelPopup?.printableLabels : []}
                genericLabelData={showPrintLabelPopup?.genericLabelData}
            />
        </>
    );
}
export default ActionScreenAskPrintLabelsV2;
